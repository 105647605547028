import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { IoArrowForward } from "react-icons/io5";

import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const JobList = styled(Row)`
  --item-gap: 40px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 3) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const JobCard = styled(Box)`
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 30px;
  box-sizing: border-box;

  ul {
    margin-left: 1rem;
  }
`;

const Examples = (props) => {
  const jobs = [
    {
      job: "Car Decks on Steep Sites",
      details: [
        "Site assessment",
        "Detailed design",
        "Construction supervision",
      ],
      links: [
        {
          text: "Durham Road Car Deck",
          link: "durham",
        },
        {
          text: "Alexandra Road Car Deck",
          link: "alexandra",
        },
        {
          text: "Wadestown Car Deck",
          link: "wadestown",
        },
      ],
    },
    {
      job: "Hillside Protection Works",
      details: [
        "Assessment of existing site conditions",
        "Remedial options and design",
        "Site supervision",
      ],
      links: [
        {
          text: "Eastbourne Hillside Protection Works",
          link: "eastbourne",
        },
        {
          text: "Seatoun Rockfall Protection",
          link: "seatoun",
        },
      ],
    },
    {
      job: "Retaining Works",
      details: [
        "Stability assessment",
        "Options and detailed design",
        "Site supervision",
      ],
      links: [
        {
          text: "Epuni Street Retaining Wall",
          link: "epuni",
        },
        {
          text: "Plimmerton Retaining Works",
          link: "plimmerton-retaining",
        },
        {
          text: "Shotcrete Facing Wall",
          link: "shotcrete",
        },
        {
          text: "Residential Retaining Wall",
          link: "residential",
        },
      ],
    },
    {
      job: "Slip Failure Remedial Work",
      details: [
        "Determine cause of failure",
        "Remedial options and detailed design",
        "Site supervision",
      ],
      links: [
        {
          text: "Normandale Slip Retaining Wall",
          link: "normandale",
        },
        {
          text: "Thorndon Slip Repair",
          link: "thorndon",
        },
        {
          text: "Melrose Slip Protection",
          link: "melrose",
        },
      ],
    },
    {
      job: "Driveway and Site Development",
      details: [
        "Design to architects' concept",
        "Documentation for consent and construction",
        "Inspections to confirm design assumptions",
      ],
      links: [
        {
          text: "Roseneath Driveway Development",
          link: "roseneath",
        },
        {
          text: "Plimmerton Site Development",
          link: "plimmerton-development",
        },
        {
          text: "Seascape Site Development",
          link: "seascape",
        },
        {
          text: "Karori Parking Area",
          link: "karori",
        },
        {
          text: "Ngaio Parking Area",
          link: "ngaio",
        },
      ],
    },
    {
      job: "Design Documentation and Supervision",
      details: [
        "Diverse range of projects",
        "Site supervision",
        "Construction documentation and calculations",
      ],
      links: [
        {
          text: "Design Documentation and Supervision",
          link: "design",
        },
      ],
    },
  ];

  return (
    <Layout>
      <Seo title="Our Projects" pathname={props.location.pathname} />
      <Container>
        <Wrapper>
          <h1 className="-textCenter">Our Projects</h1>
          <JobList isWrap justify="flex-start">
            {jobs.map((item, i) => {
              return (
                <JobCard gap={40} key={i}>
                  <Box gap={15}>
                    <h3 className="-fontBold">{item.job}</h3>
                    <ul>
                      {item.details.map((item, i) => {
                        return <li key={i}>{item}</li>;
                      })}
                    </ul>
                  </Box>
                  <Box gap={20}>
                    {item.links.map((item, i) => {
                      return (
                        <p>
                          <Link to={`/examples/` + item.link} key={i}>
                            {item.text}{" "}
                            <IoArrowForward
                              css={{ marginLeft: "3px", top: "3px" }}
                            />
                          </Link>
                        </p>
                      );
                    })}
                  </Box>
                </JobCard>
              );
            })}
          </JobList>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Examples;
